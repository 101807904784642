import React, { useState } from "react"
import "react-simple-keyboard/build/css/index.css"
import { Namaste } from "./Namaste"
import { NumPad } from "./Numpad"
import '../../utils/reset.css';
import '../../utils/styles.css';
import { Cookies } from "./Cookies"
import { Citizen } from "./Citizen"
import { Best } from "./Best"
import { Bag } from "./Bag"

export const TeamSom = () => {
  const [success, setSuccess] = useState(false);
  const [level, setLevel] = useState(1)
  const handleSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      setLevel(prevState => prevState + 1);
      setSuccess(false);
    }, 1500);
  }
  return (
    <div style={{ background: 'black', top: 0, bottom: 0, width: '100%', position: 'fixed'}}>
      {level === 1 && <NumPad handleSuccess={handleSuccess}/>}
      {level === 2 && <Cookies handleSuccess={handleSuccess}/>}
      {level === 3 && <Citizen handleSuccess={handleSuccess}/>}
      {level === 4 && <Best handleSuccess={handleSuccess}/>}
      {level > 4 && <Bag />}
      {success && <Namaste />}
    </div>
  )
}
