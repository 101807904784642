import monkey from "../../images/monkey.jpg"
import React from "react"

export const Namaste = () => (
  <div style={{ background: "black", top: 0, bottom: 0, position: "absolute", display: "flex", alignItems: "center" }}>
    <img src={monkey} style={{ width: "100%" }}/>
    <div style={{
      transform: "rotate(-45deg)",
      fontWeight: "bold",
      fontSize: "30px",
      color: "green",
      position: "absolute",
      top: "45%",
    }}>namaste
    </div>
    <div style={{
      transform: "rotate(65deg)",
      fontWeight: "bold",
      fontSize: "40px",
      color: "white",
      position: "absolute",
      top: "65%",
      left: "50px",
    }}>namaste
    </div>
    <div style={{
      transform: "rotate(165deg)",
      fontWeight: "bold",
      fontSize: "50px",
      color: "orange",
      position: "absolute",
      top: "10%",
      right: "10px",
    }}>namaste
    </div>
    <div style={{
      transform: "rotate(70deg)",
      fontWeight: "bold",
      fontSize: "50px",
      color: "green",
      position: "absolute",
      top: "75%",
      right: "20px",
    }}>namaste
    </div>
    <div style={{
      transform: "rotate(270deg)",
      fontWeight: "bold",
      fontSize: "20px",
      color: "white",
      position: "absolute",
      top: "50px",
      left: "20px",
    }}>namaste
    </div>
    <div style={{
      transform: "rotate(25deg)",
      fontWeight: "bold",
      fontSize: "50px",
      color: "orange",
      position: "absolute",
      top: "30%",
      right: "10px",
    }}>namaste
    </div>
  </div>
)
