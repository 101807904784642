import React, { useState } from "react";
import './best.css';

export const Best = ({ handleSuccess }) => {
  const [noClick, setNoClick] = useState(1);

  return (
    <div style={{ top: 0, bottom: 0, left: 0, right: 0, position: "fixed", color: 'white', padding: '50px 20px 0' }}>
      <span style={{ fontSize: '32px', lineHeight: '40px', fontWeight: 'bold'}}>Is Johnny the best boyfriend in the world?</span>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <button
          onClick={handleSuccess}
          className={noClick >= 4 ? 'pulse' : ''}
          style={{
            fontSize: `${noClick * 24}px`,
            background: 'green',
            marginTop: '30px',
            padding: '10px 5px',
            borderRadius: '10px',
            width: '100%'
          }}
        >
          Yes
        </button>
        {noClick < 4 && (
          <button
            onClick={() => setNoClick(prevState => prevState + 1)}
            style={{
              fontSize: `${1/noClick * 24}px`,
              background: 'red',
              marginTop: '30px',
              padding: '10px 5px',
              borderRadius: '10px',
              width: `${100/noClick}%`
            }}
          >
            No
          </button>
        )}
      </div>
    </div>
  );
};
