import React, { useState } from "react";

export const Cookies = ({ handleSuccess }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  return (
    <div style={{ top: 0, bottom: 0, left: 0, right: 0, position: "fixed" }}>
      <div style={{ bottom: 0, left: 0, right: 0, position: 'absolute', background: 'grey', padding: '10px' }}>
        <span style={{color: 'white'}}>We use cookies on our website for a number of purposes, including analytics and performance, functionality and advertising.</span>
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <button
            onClick={handleSuccess}
            style={{ marginRight: '20px', background: 'green', color: 'white', borderRadius: '5px', padding: '5px 10px' }}
          >
            Accept
          </button>
          <span onClick={() => setSettingsOpen(true)} style={{ color: 'white', textDecoration: 'underline', fontSize: '14px' }}>View settings</span>
        </div>
      </div>
      {settingsOpen && (
        <div style={{ padding: '20px', background: 'white', top: 0, bottom: 0, left: 0, right: 0, position: "fixed", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <span style={{ fontSize: '40px',}}>JUST ACCEPT THE COOKIES YOU NOSEY BITCH</span>
          <button
            onClick={handleSuccess}
            style={{ marginTop: '30px', background: 'green', color: 'white', borderRadius: '5px', padding: '5px 10px', fontSize: '32px' }}
          >
            Accept
          </button>
        </div>
      )}
    </div>
  )
};
