import React, { useState } from "react";

const Question = ({ question, answers, selectAnswer }) => {
  return (
    <div style={{ marginTop: '30px'}}>
      <span style={{ fontSize: '24px'}}>{question}</span>
      <div style={{ position: "absolute", bottom: '30px', left: 0, right: 0, display: 'flex', flexWrap: 'wrap'}}>
        {answers.map(answer => (
          <div
            onClick={() => selectAnswer(answer[1])}
            style={{ fontSize: '40px', padding: '20px 0', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'calc(50% - 20px)', margin: '10px'}}>
            {answer[0]}
          </div>
        ))}
      </div>
    </div>
  )
}

const questions = [
  {
    question: 'How many times have Tyrone won the All Ireland?',
    answers: [[1], [3], [4, true], [6]]
  },
  {
    question: 'Aloo parathas are the poor man\'s potato bread',
    answers: [['True', true], ['False']]
  },
  {
    question: 'Aye dead on',
    answers: [['Dublin'], ['Rest of Ireland', true]]
  },
  {
    question: 'I\'m away to the jax',
    answers: [['Dublin', true], ['Rest of Ireland']]
  },
  {
    question: 'Bake on ye like a busted shovel',
    answers: [['Dublin'], ['Rest of Ireland', true]]
  },
  {
    question: 'Ya bleedin dirt bird',
    answers: [['Dublin', true], ['Rest of Ireland']]
  },
  {
    question: 'Alright buuddy',
    answers: [['Dublin', true], ['Rest of Ireland']]
  },
  {
    question: 'Lethal lad',
    answers: [['Dublin'], ['Rest of Ireland', true]]
  },
  {
    question: 'Wee buns',
    answers: [['Dublin'], ['Rest of Ireland', true]]
  },
  {
    question: 'Bout ye?',
    answers: [['Dublin'], ['Rest of Ireland', true]]
  }
]

const getPrompt = (score) => {
  switch (score) {
    case 10:
    case 9:
    case 8:
    case 7:
      return 'CHEATER!';
    default:
      return 'Get out of my country'
  }
}

const FinalScore = ({ score, handleSuccess }) => (
  <div style={{ marginTop: '30px'}}>
    <span>You scored: {score.score} / {score.question}</span>
    <p style={{ marginTop: '30px', fontSize: '32px', fontWeight: 'bold'}}>{getPrompt(score.score)}</p>
    <button style={{ background: 'green', padding: '5px', borderRadius: '5px', marginTop: '30px', fontSize: '32px', fontWeight: 'bold'}} onClick={handleSuccess}>Continue</button>
  </div>
)

export const Citizen = ({ handleSuccess }) => {
  const [score, setScore] = useState({question: 0, score: 0});

  const selectAnswer = (correct) => {
    if (correct) {
      setScore(prevState => ({ question: prevState.question + 1, score: prevState.score + 1}))
      return;
    }
    setScore(prevState => ({ question: prevState.question + 1, score: prevState.score}))
  }

  return (
    <div style={{ top: 0, bottom: 0, left: 0, right: 0, position: "fixed", color: 'white', padding: '50px 20px 0' }}>
      <span style={{ fontSize: '32px', fontWeight: 'bold'}}>Irish Citizen Test</span>
      {score.question < 10 ? (
        <Question question={questions[score.question].question} answers={questions[score.question].answers} selectAnswer={selectAnswer}/>
      ) : <FinalScore score={score} handleSuccess={handleSuccess}/>}
    </div>
  );
};
