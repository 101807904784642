import React, { useState } from "react"
import Keyboard from "react-simple-keyboard"

const ERROR = {
  1: "qu'est-ce que c'est?",
  2: "Is that right?",
  3: "FUUUUUCK",
}
export const NumPad = ({ handleSuccess }) => {
  const [currentValue, setCurrentValue] = useState("")
  const [error, setError] = useState(0)
  const onChange = (input) => {
    setCurrentValue(input)
  }

  const onKeyPress = (button) => {
    if (button === "{enter}") {
      if (currentValue === "2708") {
        handleSuccess()
        return
      }
      setError(prevState => {
        if (error < 3) return prevState + 1
        return 1
      })
    }
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: "100%", color: 'white' }}

    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "20px",
            marginTop: "200px",
          }}>
          {error > 0 && ERROR[error]}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "20px",
            marginTop: "40px",
          }}>
          {[...new Array(currentValue.length)].fill("*").map(v => (
            <div style={{ marginRight: "5px" }}>{v}</div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: "auto", color: 'black' }}>
        <Keyboard
          layout={{ default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"] }}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  )
}
